.root {
  background: rgba(245,245,245,0.9);
}

.wrap {
 // margin-left: -10px;
}

.content {
  height: var(--top-bar-height);
  display: grid;
  align-items: center;
  align-self: center;
  justify-content: center;

  grid-template-columns: 100%;

  > * {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}

.burger {
  justify-self: start;
}

.lang_switch {
  justify-self: end;
}

.logo_container {
  justify-self: center;
  display: grid;
  margin: 0 auto;
  height: var(--top-bar-height);
  width: 60px;
  grid-template-columns: auto 1fr;
  grid-template-rows: var(--top-bar-height);

  @media screen and (min-width: 480px) {
    width: 74px;
  }

  @media screen and (min-width: 768px) {
    width: 260px;
  }

  img {
    height: 100%;
  }

  &_logo, &_wortmarke {
    height: 100%;

  }
  &_logo {
    padding: 15px;
    background-color: white;

    img {
      padding: 0 5px;
    }
  }

  &_wortmarke {
    margin-left: 20px;
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }

  }
}
