.toolbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: #eeeeee;
  padding: 20px;
  z-index: 900;
  border-top: solid 1px #C20924;
  direction: ltr;

  display: grid;
  grid-template-columns: auto auto auto 1fr ;
  //grid-auto-flow: column;
  grid-column-gap: 20px;
  justify-content: start;
  align-content: start;
}

.btn_disabled {
  cursor: unset;
  pointer-events: none;
}

.logout {
  justify-self: end;
}
