.item {

  @media screen and (min-width: 768px) {

    :global(.o-off-canvas__item-link) {
      padding-bottom: 0;
    }

    :global(.o-off-canvas__item-subline) {
      top: unset;
      margin-bottom: unset;
    }

    :global(.o-off-canvas__item-link--has-children::after) {
      top: 50%;
    }
  }

}