.wrapper {

  display: grid;
  justify-content: center;


  .content {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;

    }

    .item {

      &:first-child
      {
        background-color: #DF0A29;
      }

      background-color: #C20924;
      cursor: pointer;

      justify-content: space-between;
      overflow: hidden;



      height: 220px;
      padding: 25px;
      width: 220px;


      &:not(:first-child):not(:last-child)
      {
        margin-left: calc(100vw - 220px);
      }
      
      @media (min-width: 480px) {
        &:not(:first-child):not(:last-child) {
          margin-left: 200px;
        }

        height: 280px;
        padding: 40px 30px;
        width: 280px;
      }

      @media (min-width: 768px) {
        &:not(:first-child):not(:last-child) {
          margin-left: unset;
        }

        &:first-child,
        &:last-child {
          margin-top: 160px;
        }

        height: 230px;
        padding: 14px 20px;
        width: 230px;
      }

      @media (min-width: 1024px) {
        padding: 35px 30px;
        height: 340px;
        width: 340px;
      }

      position: relative;

      p {
        font-family: Roboto-Bold, Arial, sans-serif;
        color:#ffffff;

        font-size: 18px;
        line-height: 22px;
        max-height: 154px;
        overflow: hidden;
        width: 100% !important;

        @media (min-width: 480px) {
          font-size: 21px;
          line-height: 26px;
          max-height: 182px;
        }

        @media (min-width: 768px) {
          font-size: 18px;
          line-height: 24px;
          max-height: 168px;
        }

        @media (min-width: 1024px) {
          font-size: 27px;
          line-height: 35px;
          max-height: 245px;
        }

        @media (min-width: 1280px) {
          font-size: 28px;
          line-height: 35px;
          max-height: 245px;
        }
      }

      &:hover .arrow {
        transform: translateX(5px);
      }

      .arrow {

        bottom: 15px;
        font-size: 30px;
        margin: 0;
        position: absolute;
        right: 17px;
        text-align: right;
        transition: 0.5s ease-in-out;
        width: auto;

        @media (min-width: 480px) {
          bottom: 15px;
          font-size: 40px;
          right: 15px;
        }

        @media (min-width: 768px) {
          bottom: 10px;
          font-size: 37px;
          right: 11px;
        }

        @media (min-width: 1024px) {
          bottom: 15px;
          font-size: 50px;
          right: 17px;
        }

        &::after {
          content: "";
          font-family: mef-iconset-bold, Roboto-Light, Arial, sans-serif;
          -webkit-font-smoothing: antialiased;
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          speak: none;
          text-transform: none;
          font-variant: normal;

        }
      }
    }
  }
}
