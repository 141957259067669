.stage_item
{
  display: grid;


  .overlay
  {
    grid-row: 1/2;
    grid-column: 1/3;
    z-index: 2;

    display: grid;
    grid-template-rows: var(--top-bar-height) auto;

    align-self: stretch;
    justify-self: center;

    width: 100%;
    justify-content: stretch;



    @media screen and (min-width: 768px) {
     // margin-left: 50%;
    }

    .cube_wrapper {
      display: grid;

      margin: 0 22px;
      grid-row: 2/3;

      grid-template-rows: auto 1fr;




      @media screen and (min-width: 480px) {
        justify-content: center;
      }
      @media screen and (min-width: 768px) {

        //grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (min-width: 1280px) {
        transform: translateX(-94px);
      }


      height: 100% !important;
    }



    .cube_big, .cube_small {
      padding: 17px 20px;
    }

    .cube_big
    {
      height: 230px;
      width: 230px;
      font-family: "Roboto-Bold",sans-serif;
      font-weight: 700;

      justify-self: start;

      @media screen and (min-width: 768px) {
        padding: 17px 20px;
        height: 340px;
        width: 340px;
        justify-self: end;
        font-size: 32px;
        line-height: 40px;
      }

      @media screen and (min-width: 1024px) {

        padding: 35px 30px;
      }

      //justify-self: center;

      background: rgba(219,7,32,0.9);

      font-size: 22px;
      line-height: 28px;
    }

    .cube_small
    {
      background: rgba(149,5,22,0.9);
      font-size: 14px;
      line-height: 18px;
      font-family: "Roboto-Bold",sans-serif;
      font-weight: 700;

      width: 160px;
      height: 160px;

      justify-self: start;

      margin-left: 160px;
      margin-bottom: 50px;

      @media screen and (min-width: 768px) {
        height: 208px;
        width: 208px;
        margin-left: 0;
        justify-self: end;
        transform: translateX(50%);
        grid-row: 2/3;
        font-size: 16.5px;
        line-height: 22px;
      }

      @media screen and (min-width: 1024px) {

      }
    }
  }

  .image {

    width: 100%;

    grid-row: 1/2;
    grid-column: 1/3;

    min-height: 280px;
    max-height: var(--max-stage-height);


    img {
      position: absolute;

      object-fit: cover;
      height: 100%;
      width: 100%;
    }


    overflow: hidden;
    justify-items: center;
    align-items: center;

    background-position: center;

    @media screen and (min-width: 768px)  {
      min-height: 440px;
    }

    @media screen and (min-width: 1024px) {
   //   min-height: 560px;
    }

    figure {
      position: relative;

      height: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }


  }
}

html[dir=rtl] .cube_big, html[dir=rtl] .cube_small {
  direction: rtl;
}

/*
  margin-bottom: 40px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 60px;
  }
 */